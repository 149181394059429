<template>
	<div>
		<div class="page-title">
			<a>
				<router-link to="/reseau/check/index">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">编辑网格排查</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" class="w-50">
				<el-form-item label="网格修改" prop="reseau_name">
					<el-input size="mini" style="width: 300px;" disabled v-model="form.reseau_name"></el-input>
					<el-button class="ml-1" size="mini" @click="openDialog()">选择</el-button>
				</el-form-item>
				<el-form-item label="场所名称" prop="name">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="场所地址" prop="address">
					<el-input v-model="form.address"></el-input>
				</el-form-item>
				<el-form-item label="门牌照片" prop="doorplate_image">
					<app-attachment :multiple="false" :max="1" v-model="form.doorplate_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.doorplate_image" :show-delete="true" @deleted="form.doorplate_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item label="业主姓名" prop="real_name">
					<el-input v-model="form.real_name"></el-input>
				</el-form-item>
				<el-form-item label="业主手机号" prop="mobile">
					<el-input v-model="form.mobile"></el-input>
				</el-form-item>
				<el-form-item label="日期期限" prop="deadline_time">
					<el-date-picker v-model="form.deadline_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="等级类型" prop="type">
					<el-radio v-model="form.type" :value="10" :label="10">
						三合一
					</el-radio>
					<el-radio v-model="form.type" :value="20" :label="20">
						出租屋
					</el-radio>
					<el-radio v-model="form.type" :value="30" :label="30">
						自建房
					</el-radio>
				</el-form-item>
				<el-form-item label="面积" prop="area">
					<el-radio v-model="form.area" :value="10" :label="10">
						50平以下
					</el-radio>
					<el-radio v-model="form.area" :value="20" :label="20">
						50-100平
					</el-radio>
					<el-radio v-model="form.area" :value="30" :label="30">
						100-200平
					</el-radio>
					<el-radio v-model="form.area" :value="40" :label="40">
						200-300平
					</el-radio>
					<el-radio v-model="form.area" :value="50" :label="50">
						300平以上
					</el-radio>
				</el-form-item>
				<el-form-item label="层数" prop="plies_num">
					<el-radio v-model="form.plies_num" :value="10" :label="10">
						1-3层
					</el-radio>
					<el-radio v-model="form.plies_num" :value="20" :label="20">
						3-5层
					</el-radio>
					<el-radio v-model="form.plies_num" :value="30" :label="30">
						5层以上
					</el-radio>
				</el-form-item>
				<el-form-item label="在住人数" prop="reside_num">
					<el-radio v-model="form.reside_num" :value="10" :label="10">
						1-3人
					</el-radio>
					<el-radio v-model="form.reside_num" :value="20" :label="20">
						4-5人
					</el-radio>
					<el-radio v-model="form.reside_num" :value="30" :label="30">
						6-9人
					</el-radio>
					<el-radio v-model="form.reside_num" :value="40" :label="40">
						10人以上
					</el-radio>
				</el-form-item>
				<el-form-item label="违规住人" prop="is_person">
					<el-radio v-model="form.is_person" :value="10" :label="10">
						无住人
					</el-radio>
					<el-radio v-model="form.is_person" :value="20" :label="20">
						有住人
					</el-radio>
				</el-form-item>
				<el-form-item label="是否撤离" prop="is_evacuate">
					<el-radio v-model="form.is_evacuate" :value="10" :label="10">
						否
					</el-radio>
					<el-radio v-model="form.is_evacuate" :value="20" :label="20">
						已撤离
					</el-radio>
				</el-form-item>
				<el-form-item label="住人撤离图片" prop="person_image">
					<app-attachment :multiple="false" :max="1" v-model="form.person_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.person_image" :show-delete="true" @deleted="form.person_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item label="是否开设逃生" prop="is_escape">
					<el-radio v-model="form.is_escape" :value="10" :label="10">
						否
					</el-radio>
					<el-radio v-model="form.is_escape" :value="20" :label="20">
						是
					</el-radio>
				</el-form-item>
				<el-form-item label="开设逃生图片" prop="escape_image">
					<app-attachment :multiple="false" :max="1" v-model="form.escape_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.escape_image" :show-delete="true" @deleted="form.escape_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item label="是否落实防火分隔" prop="is_separate">
					<el-radio v-model="form.is_separate" :value="10" :label="10">
						否
					</el-radio>
					<el-radio v-model="form.is_separate" :value="20" :label="20">
						是
					</el-radio>
				</el-form-item>
				<el-form-item label="落实防火分隔图片" prop="separate_image">
					<app-attachment :multiple="false" :max="1" v-model="form.separate_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.separate_image" :show-delete="true" @deleted="form.separate_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item label="是否安装独立烟感" prop="is_smoke">
					<el-radio v-model="form.is_smoke" :value="10" :label="10">
						否
					</el-radio>
					<el-radio v-model="form.is_smoke" :value="20" :label="20">
						是
					</el-radio>
				</el-form-item>
				<el-form-item label="安装独立烟感图片" prop="smoke_image">
					<app-attachment :multiple="false" :max="1" v-model="form.smoke_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.smoke_image" :show-delete="true" @deleted="form.smoke_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item label="是否配备防毒面罩" prop="is_defense">
					<el-radio v-model="form.is_defense" :value="10" :label="10">
						否
					</el-radio>
					<el-radio v-model="form.is_defense" :value="20" :label="20">
						是
					</el-radio>
				</el-form-item>
				<el-form-item label="配备防毒面罩图片" prop="defense_image">
					<app-attachment :multiple="false" :max="1" v-model="form.defense_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.defense_image" :show-delete="true" @deleted="form.defense_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item label="是否开展宣传培训" prop="is_train">
					<el-radio v-model="form.is_train" :value="10" :label="10">
						否
					</el-radio>
					<el-radio v-model="form.is_train" :value="20" :label="20">
						是
					</el-radio>
				</el-form-item>
				<el-form-item label="开展宣传培训图片" prop="train_image">
					<app-attachment :multiple="false" :max="1" v-model="form.train_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.train_image" :show-delete="true" @deleted="form.train_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item label="电器线路是否套管铺设" prop="is_circuit">
					<el-radio v-model="form.is_circuit" :value="10" :label="10">
						否
					</el-radio>
					<el-radio v-model="form.is_circuit" :value="20" :label="20">
						是
					</el-radio>
				</el-form-item>
				<el-form-item label="电器线路是否套管铺设图片" prop="circuit_image">
					<app-attachment :multiple="false" :max="1" v-model="form.circuit_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.circuit_image" :show-delete="true" @deleted="form.circuit_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item label="闸刀开关是否使用" prop="is_knife">
					<el-radio v-model="form.is_knife" :value="10" :label="10">
						否
					</el-radio>
					<el-radio v-model="form.is_knife" :value="20" :label="20">
						是
					</el-radio>
				</el-form-item>
				<el-form-item label="闸刀开关是否使用图片" prop="knife_image">
					<app-attachment :multiple="false" :max="1" v-model="form.knife_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.knife_image" :show-delete="true" @deleted="form.knife_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item label="是否违规存放电动车" prop="is_electrocar">
					<el-radio v-model="form.is_electrocar" :value="10" :label="10">
						否
					</el-radio>
					<el-radio v-model="form.is_electrocar" :value="20" :label="20">
						是
					</el-radio>
				</el-form-item>
				<el-form-item label="是否违规存放电动车图片" prop="electrocar_image">
					<app-attachment :multiple="false" :max="1" v-model="form.electrocar_image">
						<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
					</app-attachment>
					<app-gallery :url="form.electrocar_image" :show-delete="true" @deleted="form.electrocar_image = ''"
						width="80px" height="80px"></app-gallery>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-dialog title="网格列表" :visible.sync="dialogVisible" top="3vh" width="65%" height="60%">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-input style="width: 200px" size="small" v-model="search.keyword" placeholder="网格名称" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
					</el-input>
				</div>
				<div class="d-flex align-items-center m-1">
					<div class="label ">单位：</div>
					<el-select @change="handleFirstChange" v-model="search.first_id" placeholder="请选择支队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in firstList" :key="item.unit_id" :label="item.unit_name"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select @change="handleSecondChange" v-model="search.second_id" placeholder="请选择大队">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in secondList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
					<el-select v-model="search.third_id" placeholder="请请选择街道">
						<el-option label="请选择" :key="0" :value="0">
						</el-option>
						<el-option v-for="item in thirdList" :label="item.unit_name" :key="item.unit_id"
							:value="item.unit_id">
						</el-option>
					</el-select>
				</div>
				<el-button class="ml-1" @click="toSearch" type="primary" size="mini" icon="el-icon-search">
				</el-button>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" label="网格名称">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="单位">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
						<template v-if="scope.row.second_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
						</template>
						<template v-if="scope.row.third_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<template v-if="scope.row.reseau_id == form.reseau_id">已选择</template>
						<el-button v-else type="text" size="mini" @click="selectReseau(scope.row)">选择</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">关闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				loading: false,
				dialogVisible: false,
				lists: [],
				firstList: [],
				secondList: [],
				thirdList: [],
				search: {
					s: 'store/unit.reseau/index',
					keyword: '',
					page: 1,
					first_id: 0,
					second_id: 0,
					third_id: 0,
				},
				form: {
					type: 10,
					area: 10,
					plies_num: 10,
					reside_num: 10,
					reseau_name: '',
					reseau_id: 0,
					name: "",
					address: "",
					doorplate_image: "",
					real_name: "",
					mobile: "",
					deadline_time: "",
					is_person: 10,
					is_evacuate: 10,
					person_image: "",
					is_escape: 10,
					escape_image: "",
					is_separate: 10,
					separate_image: "",
					is_smoke: 10,
					smoke_image: "",
					is_defense: 10,
					defense_image: "",
					is_train: 10,
					train_image: "",
					is_circuit: 10,
					circuit_image: "",
					is_knife: 10,
					knife_image: "",
					is_electrocar: 10,
					electrocar_image: "",
				},
				rules: {
					reseau_name: [{
						required: true,
						message: '请选择网格',
						trigger: 'change'
					}, ],
				},
			}
		},
		created() {
			this.getUnitList()
			this.getList()
			this.getDetail()
		},
		methods: {
			getDetail() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/reseau.check/edit',
						reseau_check_id: this.$route.params.reseau_check_id,
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.form.reseau_name = res.data.data.reseau.name;
					this.form.reseau_id = res.data.data.reseau.reseau_id;
					this.form.type = res.data.data.type;
					this.form.area = res.data.data.area;
					this.form.plies_num = res.data.data.plies_num;
					this.form.reside_num = res.data.data.reside_num;
					this.form.name = res.data.data.name;
					this.form.address = res.data.data.address;
					this.form.doorplate_image = res.data.data.doorplate_image;
					this.form.real_name = res.data.data.real_name;
					this.form.mobile = res.data.data.mobile;
					this.form.deadline_time = res.data.data.deadline_time;
					this.form.is_person = res.data.data.is_person;
					this.form.is_evacuate = res.data.data.is_evacuate;
					this.form.person_image = res.data.data.person_image;
					this.form.is_escape = res.data.data.is_escape;
					this.form.escape_image = res.data.data.escape_image;
					this.form.is_separate = res.data.data.is_separate;
					this.form.separate_image = res.data.data.separate_image;
					this.form.is_smoke = res.data.data.is_smoke;
					this.form.smoke_image = res.data.data.smoke_image;
					this.form.is_defense = res.data.data.is_defense;
					this.form.defense_image = res.data.data.defense_image;
					this.form.is_train = res.data.data.is_train;
					this.form.train_image = res.data.data.train_image;
					this.form.is_circuit = res.data.data.is_circuit;
					this.form.circuit_image = res.data.data.circuit_image;
					this.form.is_knife = res.data.data.is_knife;
					this.form.knife_image = res.data.data.knife_image;
					this.form.is_electrocar = res.data.data.is_electrocar;
					this.form.electrocar_image = res.data.data.electrocar_image;
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			handleFirstChange(first_id) {
				this.search.second_id = 0;
				this.search.third_id = 0;
				for (let i = 0; i < this.firstList.length; i++) {
					if (this.firstList[i]['unit_id'] == first_id) {
						this.secondList = this.firstList[i].child;
					}
				}
			},
			handleSecondChange(second_id) {
				this.search.third_id = 0;
				for (let i = 0; i < this.secondList.length; i++) {
					if (this.secondList[i]['unit_id'] == second_id) {
						this.thirdList = this.secondList[i].child;
					}
				}
			},
			selectReseau(item) {
				this.form.name = item.name;
				this.form.reseau_id = item.reseau_id;
				this.dialogVisible = false;
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			openDialog() {
				// 显示弹出层
				this.dialogVisible = true
			},

			getUnitList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/unit/index',
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.firstList = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/reseau.check/edit',
								reseau_check_id: this.$route.params.reseau_check_id,
							},
							data: {
								check: this.form
							},
							method: 'post',
						}).then(res => {
							this.loading = false;
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'reseau.check/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
							this.loading = false;
						})
					}
				})
			}
		}
	}
</script>

<style>
</style>
